import { CalendarIcon, SearchIcon } from '@heroicons/react/outline';
import imgChainLogo from './../../images/dl-chain-logo.png';
import drWork from './../../images/doctor-work.jpg';
import { Controller } from 'react-hook-form';
import useSearch from '../hooks/useSearch';
import FormInputField from '../../common/components/formElements/FormInputField';
import FormDatePicker from '../../common/components/formElements/FormDatePicker';
import FormComboBox from '../../common/components/formElements/FormComboBox';
import { Link, useSearchParams } from 'react-router-dom';
import imgsearch from './../../images/serch-icon.png';
import { useEffect } from 'react';
import useSearchResults from '../../searchResults/hooks/useSearchResults';

const auxDate = new Date();
const MIN_DATE = new Date(auxDate.setDate(auxDate.getDate() + 1));
export default function Search() {
  const [searchParams] = useSearchParams();
  const { onSearch, register, handleSubmit, control, formError, cities, error, getValues, reset } = useSearch();
  const { getCurrentSearchParams } = useSearchResults();

  useEffect(() => {
    const { date, city, q } = getCurrentSearchParams();
    const selectCity = cities.find((item) => item.id.toString() === city);
    reset({ specialty: q, city: selectCity });
  }, [searchParams, cities]);

  return (
    <div className="bg-dl-screen-lightblue  lg:px-8 mx-auto px-4 relative sm:px-6">
      <div className="grid grid-cols-12 gap-12">
        <div className="col-span-12 md:col-span-12 search-box-top-main">
          {/* <h2 className="docter-link-title text-center">
            <b>Los especialistas médicos</b> reunidos en una sola plataforma
          </h2> */}
          <form onSubmit={handleSubmit(onSearch)} style={{ margin: '0 auto' }} className="docter-link-form">
            {formError && <p className="text-sm text-red-700 error-msge">Debe llenar al menos un campo</p>}
            {error !== '' && (
              <div className="mb-8 bg-blue-100 border-t-4 border-blue-500 rounded-b text-blue-900 px-4 py-3 shadow-md" role="alert">
                <div className="flex">
                  <div className="py-1">
                    <svg className="fill-current h-6 w-6 text-blue-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </div>
                  <div>
                    <p className="font-bold mb-4">{error}</p>
                    <Link to="/sign-in" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      Inicia sesión
                    </Link>
                  </div>
                </div>
              </div>
            )}
            <div className="form-field-box-main">
              <div className="field-1 form-field-box z-30">
                <FormInputField
                  label="Especialidad o nombre"
                  type="text"
                  name="buscar"
                  id="buscar"
                  placeholder={''}
                  // iconComponent={<SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                  registration={register('specialty')}
                />
              </div>

              <div className="field-2 form-field-box">
                <Controller
                  control={control}
                  name="city"
                  render={({ field }) => (
                    <FormComboBox
                      label="Ciudad"
                      data={cities}
                      control={control}
                      value={field.value}
                      onChange={(value) => {
                        console.log(value);
                        field.onChange(value);
                      }}
                    />
                  )}
                />
              </div>

              <div className="field-3 form-field-box">
                <Controller
                  control={control}
                  name="date"
                  render={({ field }) => (
                    <FormDatePicker
                      identifier="date"
                      label="Fecha"
                      //  iconComponent={<CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                      datePickerProps={{
                        selected: field.value,
                        dateFormat: 'dd/MM/yyyy',
                        onChange: (date) => field.onChange(date),
                        minDate: MIN_DATE,
                        peekNextMonth: true,
                        showMonthDropdown: true,
                        showYearDropdown: false,
                        dropdownMode: 'select'
                      }}
                    />
                  )}
                />
              </div>

              <div className="search-btn-box">
                <button type="submit" className="submit-btn">
                  <div>
                    <img className="h-8 w-auto mr-1.5 flex justify-center" src={imgsearch} alt="DoctoLink" />
                  </div>
                  Buscar
                </button>
              </div>
            </div>
          </form>
        </div>
        {/* <div className="col-span-12 md:col-span-6 relative">
          <img className="absolute inset-0 h-full w-full object-cover" src={drWork} alt="" />
        </div> */}
      </div>
    </div>
  );
}
