// Esto vendra del servicio web

import { Link } from 'react-router-dom';

const search = 'search-results?q=';
const projects = [
  { name: 'Anestesiología', initials: 'A', href: search + 'Anestesiología', members: 0, bgColor: 'bg-dl-secondary' },
  { name: 'Cardiología Clínica', initials: 'CC', href: search + 'Cardiología Clínica', members: 12, bgColor: 'bg-dl-secondary' },
  { name: 'Dermatología', initials: 'D', href: search + 'Dermatología', members: 16, bgColor: 'bg-dl-secondary' },
  { name: 'Gastroenterología', initials: 'G', href: search + 'Gastroenterología', members: 8, bgColor: 'bg-dl-secondary' },
  { name: 'Hematología', initials: 'H', href: search + 'Hematología', members: 0, bgColor: 'bg-dl-secondary' },
  { name: 'Medicina Interna', initials: 'MI', href: search + 'Medicina Interna', members: 12, bgColor: 'bg-dl-secondary' },
  { name: 'Nefrología', initials: 'N', href: search + 'Nefrología', members: 16, bgColor: 'bg-dl-secondary' },
  { name: 'Oftalmología', initials: 'O', href: search + 'Oftalmología', members: 8, bgColor: 'bg-dl-secondary' }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Specialty() {
  return (
    <div className="our-specility pb-12  px-0">
      <div className="wrapper-box">
        <h2 className="common-title blue-title pb-5">Lista de especialidades</h2>
        <ul className=" grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
          {projects.map((project) => (
            <Link key={project.name} to={project.href} className="text-dl-accent font-medium hover:text-gray-600 relative ">
              <li className="col-span-1 flex shadow-sm rounded-md">
                <div className={classNames(project.bgColor, 'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md hover:bg-dl-accent blue-box-number')}>{project.initials}</div>
                <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                  <div className="flex-1 px-4 py-4 text-md truncate white-box-text">{project.name}</div>
                </div>
              </li>

              <div className='hover-content-box z-10 hidden bg-white absolute top-20  left-0 right-0  rounded-[24px] px-8 py-8 '>
                <p className='text-sm	text-base	text-center text-black'>La anestesiología es la rama de la medicina dedicada al alivio del dolor y al total cuidado del paciente antes, durante y después de un acto quirúrgico.</p>

              </div>
            </Link>
          ))}
        </ul>
        
      </div>
    </div>
  );
}
