export default function FormTextArea(props) {
  const { label, identifier, rows, placeholder, helperText, registration, errorMessage, disabled, value, onChange } = props;

  return (
    <>
      <label htmlFor="governmentPermit" className="block font-medium mb-2 text-black text-sm ">
        {label}
      </label>
      <div className="mt-1">
        <textarea
          id={identifier}
          name={identifier}
          rows={rows}
          className="shadow-sm focus:ring-dl-primary-500 focus:border-dl-primary-500 mt-1 block w-full sm:text-sm  border border-gray-300 rounded-full h-22 resize-none py-2 px-6 font-normal	"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          {...registration}
          disabled={disabled}
        />
      </div>
      {!errorMessage && helperText && <p className="mt-2 text-sm text-gray-500 font-normal text-right text-black	 pl-3 mb-4">{helperText}</p>}
      {errorMessage ? <p className="mt-2 text-sm text-red-700">{errorMessage}</p> : null}

     
    </>
  );
}
